import React from "react"
import { Link } from "gatsby"
import Div100vh from "react-div-100vh"

export default function home() {
  return (
    <Div100vh id="entire">
      <header>
        <nav>
          <ul id="headerMenu">
            <li className="menuItem" id="name">
              <Link to="/">hannah s. kim</Link>
            </li>
            <li className="menuItem" id="about">
              <Link to="/about">about</Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="hero">
        <div className="heroText">
          <p>
            Hi, I'm Hannah! I'm a user experience researcher who studied
            psychology and human-computer interaction at Carnegie Mellon
            University. Currently at Workday, I build time, core HR, and payroll
            products.
          </p>
        </div>
        <div className="workSamples">
          <h1 className="heroSectionProjectTitles">Select Work: Overview</h1>
          <ul>
            <li className="sectionListItem" id="indexListItem">
              <Link to="/workdaypayondemand">■ Workday: Pay On-Demand</Link>
            </li>
            <li className="sectionListItem" id="indexLastListItem">
              <Link to="/cmuhcii">
                ■ Carnegie Mellon's Human-Computer Interaction Institute:
                "Tabhoarding" Research
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <footer>
        <ul id="footerLinks">
          <li>
            <a
              href="mailto: hello@hannahs.kim"
              className="footerItem"
              id="email"
            >
              hello@hannahs.kim→
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/hannahs-kim"
              className="footerItem"
              id="linkedIn"
            >
              My LinkedIn→
            </a>
          </li>
        </ul>
      </footer>
    </Div100vh>
  )
}
